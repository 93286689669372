import { gql } from "@apollo/client";

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
export const UPDATE_ESTABLISHMENT = gql`
  mutation updateEstablishment($id: String, $input: UpdateEstablishmentInput) {
    updateEstablishment(id: $id, input: $input)
  }
`;
export const UPDATE_TEAM = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input)
  }
`;
export const CREATE_TEAM = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      _id
      managerId
      name
      description
      image
      code
    }
  }
`;
export const LOGIN_ADMIN = gql`
  mutation loginAdmin($input: LoginUserInput!) {
    loginAdmin(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const LOAD_ME = gql`
  {
    me {
      _id
      local {
        email
      }
      firstName
      lastName
      fullName
      avatar
      gender
      country
      reason
    }
  }
`;

export const CREATE_CODES = gql`
  mutation createCode($input: CreateCodeInput!) {
    createCode(input: $input) {
      _id
      type
      code
    }
  }
`;

export const UPDATE_CODE = gql`
  mutation updateCode($id: ID!, $input: UpdateCodeInput) {
    updateCode(_id: $id, input: $input) {
      _id
      type
      code
    }
  }
`;

export const UPDATE_CROUD_FUNDED = gql`
  mutation updateCroudFunded($id: String, $input: UpdateCroudFundedInput) {
    updateCroudFunded(_id: $id, input: $input) {
      _id
      name
    }
  }
`;
export const UPDATE_GAIN = gql`
  mutation updateGain($id: String, $input: UpdateGainInput) {
    updateGain(_id: $id, input: $input) {
      _id
      name
    }
  }
`;
export const UPDATE_SPONSORISEDPOST = gql`
  mutation updateSponsorisedPost(
    $id: String
    $input: UpdateSponsorisedPostInput
  ) {
    updateSponsorisedPost(id: $id, input: $input)
  }
`;
export const UPDATE_OFFER = gql`
  mutation updateSwOffer($id: String, $input: UpdateSwOfferInput) {
    updateSwOffer(_id: $id, input: $input) {
      _id
      name
    }
  }
`;

export const UPDATE_MARKET_PLACE_PICTURE = gql`
  mutation uploadMarketPlacePicture($file: Upload!) {
    uploadMarketPlacePicture(file: $file)
  }
`;

export const CREATE_FEED_AD = gql`
  mutation createFeedAd($input: CreateFeedAdInput!) {
    createFeedAd(input: $input) {
      _id
      link
      image
    }
  }
`;

export const UPDATE_FEED_AD = gql`
  mutation updateFeedAd($id: ID!, $input: UpdateFeedAdInput) {
    updateFeedAd(_id: $id, input: $input)
  }
`;

export const CREATE_JACKPOT = gql`
  mutation createJackpot($input: CreateJackpotInput!) {
    createJackpot(input: $input) {
      _id
      text
      image
    }
  }
`;

export const UPDATE_JACKPOT = gql`
  mutation updateJackpot($id: ID!, $input: UpdateJackpotInput) {
    updateJackpot(_id: $id, input: $input)
  }
`;

export const GET_FEEDADS = gql`
  query getFeedAds {
    getFeedAds {
      _id
      image
      link
    }
  }
`;

export const GET_ONE_FEEDADS = gql`
  query getFeedAdById($id: ID!) {
    getFeedAdById(_id: $id) {
      _id
      image
      link
      createdAt
      updatedAt
    }
  }
`;

export const GET_DELETE_FEEDADS = gql`
  mutation deleteFeedAd($id: ID!) {
    deleteFeedAd(_id: $id)
  }
`;

export const GET_GAINS = gql`
  query getGainsBackoffice {
    getGainsBackoffice {
      _id
      name
      images
      subtitle
      details
      active
      description
      active
      price {
        spows
        parrains
      }
      categories {
        _id
        name
      }
    }
  }
`;

export const CREATE_GAIN = gql`
  mutation createGain($input: CreateGainInput!) {
    createGain(input: $input) {
      _id
      name
      images
      subtitle
      details
      description
      active
      price {
        spows
        parrains
      }
      categories {
        _id
        name
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getSwCategories {
    getSwCategories {
      _id
      name
      type
      samples
      description
    }
  }
`;

export const GET_ONE_CATEGORIE = gql`
  query getSwCategoryById($id: ID!) {
    getSwCategoryById(_id: $id) {
      _id
      name
      type
      description
    }
  }
`;

export const DELETE_GAIN = gql`
  mutation deleteGain($id: ID!) {
    deleteGain(_id: $id)
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createSwCategory($input: CreateSwCategoryInput!) {
    createSwCategory(input: $input) {
      _id
      name
      type
      description
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteSwCategory($id: ID!) {
    deleteSwCategory(_id: $id)
  }
`;

export const GET_OFFERS = gql`
  query getSwOffersBackoffice {
    getSwOffersBackoffice {
      _id
      name
      owner {
        _id
        name
      }
      country
      city
      discount
      discountCode {
        _id
        type
      }
      distanceToShowOffer
      duration
      subtitle1
      subtitle2
      price
      startDate
      type
      categories {
        _id
        name
      }
      currency
      UrlLinkOffer
      description
      offerDetail
      offerInstruction
      images
      active
    }
  }
`;

export const CREATE_OFFER = gql`
  mutation createSwOffer($input: CreateSwOfferInput!) {
    createSwOffer(input: $input) {
      _id
      name
    }
  }
`;

export const GET_DISCOUNTCODE = gql`
  query codes {
    codes {
      _id
      type
      code
    }
  }
`;

export const GET_PARTENERS = gql`
  query getSwPartners {
    getSwPartners {
      _id
      name
      logo
      picture
      adresseLine
      iconPicto
      croudFunds {
        _id
        name
        images
      }
      offers {
        _id
        name
      }
      discountCodes {
        _id
        code
        offer {
          _id
          name
        }
        partner {
          _id
          name
        }
      }
      zipCode
      country
      city
      catchementCity
      catchementCountry
    }
  }
`;

export const GET_USERS = gql`
  query users($limit: Int!, $offset: Int!) {
    users(limit: $limit, offset: $offset) {
      _id
      firstName
      lastName
      avatar
      fullName
      local {
        email
      }
      google {
        email
      }
      facebook {
        email
      }
    }
  }
`;

export const GET_JACKPOTS = gql`
  query getJackpots {
    getJackpots {
      _id
      text
      image
    }
  }
`;

export const GET_EXERCICESTYPES = gql`
  query getExerciceTypesBO {
    getExerciceTypesBO {
      _id
      name
      garminName
      polarName
      suuntoName
      fitbitName
      appleName
      isActive
      defaultImage
      calsPerHour
      icon
      isGPS
    }
  }
`;

export const CREATE_EXERCICETYPES = gql`
  mutation createExerciceTypes($input: CreateExerciceTypeInput!) {
    createExerciceTypes(input: $input) {
      _id
      name
      garminName
      polarName
      suuntoName
      fitbitName
      calsPerHour
      appleName
      isActive
      defaultImage
      icon
      isGPS
    }
  }
`;

export const DELETE_JACKPOT = gql`
  mutation deleteJackpot($id: ID!) {
    deleteJackpot(_id: $id)
  }
`;

export const GET_SPONSORISEDPOST = gql`
  query getSponsorisedPostsBackoffice {
    getSponsorisedPostsBackoffice {
      _id
      offer
      croudFund
      gain
      type
      title
      subTitle
      content {
        _id
        offer
        croudFund
        gain
        type
        title
        text
        image
        video
      }
      canClose
      isFirst
      coverImage
      active
    }
  }
`;

export const DELETE_SPONSORISEDPOST = gql`
  mutation deleteSponsorisedPost($id: ID!) {
    deleteSponsorisedPost(_id: $id)
  }
`;

export const GET_ESTABLISHMENTS = gql`
  query getEstablishmentsBackoffice {
    getEstablishmentsBackoffice {
      _id
      image
      name
      address {
        lat
        lng
      }
      logo
      type
      caloriesPerMinute
      activityType
      value
      useGps
    }
  }
`;

export const CREATE_ESTABLISHMENT = gql`
  mutation createEstablishment($input: CreateEstablishmentInput!) {
    createEstablishment(input: $input) {
      _id
      image
      name
      address {
        lat
        lng
      }
      logo
      type
      activityType
      caloriesPerMinute
      value
      useGps
    }
  }
`;

export const GET_CROUDFUNDEDS = gql`
  query getCroudFundedsBackoffice {
    getCroudFundedsBackoffice(page: null, limit: null) {
      _id
      name
      images
      videoCover
      video
      remainingTime
      subtitle1
      subtitle2
      distanceToShowDonation
      projectDescription
      startingDate
      currency
      status
      amount_to_rise_goal
      team_id
      teamGroup_id
      donationInProgress
      donationCeiling
      categories {
        _id
        name
      }
      picto
      owner {
        _id
        firstName
        lastName
        fullName
        avatar
        local {
          email
        }
      }
      biography
    }
  }
`;

export const CREATE_CROUDFUNDEDS = gql`
  mutation createCroudFunded($input: CreateCroudFundedInput!) {
    createCroudFunded(input: $input) {
      _id
      name
      subtitle1
      subtitle2
      distanceToShowDonation
      startingDate
      team_id
      currency
      amount_to_rise_goal
      remainingTime
      status
      donationInProgress
      donationCeiling
      projectDescription
      videoCover
      images
      picto
      givers {
        _id
        firstName
        avatar
      }
      biography
      video
    }
  }
`;

export const DELETE_CROUDFUNDED = gql`
  mutation deleteCroudFunded($id: ID!) {
    deleteCroudFunded(_id: $id)
  }
`;

// export const DELETE_CATEGORY = gql`
// mutation deleteSwCategory($id: ID!) {
//   deleteSwCategory(_id: $id)
// }`;

export const GET_TEAMS = gql`
  query getTeams {
    getTeams {
      _id
      managerId
      name
      description
      image
      code
    }
  }
`;

export const GET_NEWTEAMS = gql`
  query getTeams {
    getTeams {
      _id
      description
      image
      managerId
      name
      pro
      code
      teamGroups {
        _id
        name
      }
      frontArticle {
        _id
        smallTitle
        title
        image
      }
    }
  }
`;

// export const GET_FRONTPAGEARTICLE = gql`
// query getTeams {
//   getTeams {
//     frontArticle
//   }
// }`;

export const GET_NEWTEAMS_BY_ID = gql`
  query getTeamById($id: ID!) {
    getTeamById(_id: $id) {
      _id
      description
      image
      managerId
      name
      pro
      code
      teamGroups {
        _id
        name
      }
    }
    # frontArticle
  }
`;

export const CREATE_NEWTEAM = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      description
      image
      managerId
      name
      pro
      code
    }
  }
`;

export const UPDATE_NEWTEAMS = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input)
  }
`;

export const DELETE_NEWTEAM = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(_id: $id)
  }
`;

export const GET_GROUP_TEAMS = gql`
  query getTeamGroup {
    getTeamGroup {
      _id
      description
      image
      managerId
      name
      team {
        _id
        name
      }
      maxUsersByTeamSubGroup
      frontArticle {
        _id
        smallTitle
        title
        image
      }
    }
  }
`;

export const UPDATE_GROUP_TEAMS = gql`
  mutation updateTeamGroup($input: UpdateTeamGroupInput!) {
    updateTeamGroup(input: $input)
  }
`;

export const CREATE_GROUP_TEAM = gql`
  mutation createTeamGroup($input: CreateTeamGroupInput!) {
    createTeamGroup(input: $input) {
      _id
      description
      image
      managerId
      name
      maxUsersByTeamSubGroup
      team {
        _id
        name
      }
    }
  }
`;

export const DELETE_GROUP_TEAMS = gql`
  mutation deleteTeamGroup($id: ID!) {
    deleteTeamGroup(_id: $id)
  }
`;

//Blog category
export const GET_BLOG_CATEGORYS = gql`
  query getBlogCategorys {
    getBlogCategorys {
      _id
      name
      image
      description
      articles {
        _id
      }
      show
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const CREATE_BLOG_CATEGORY = gql`
  mutation createBlogCategory($input: CreateBlogCategoryInput!) {
    createBlogCategory(input: $input) {
      _id
      name
      image
      description
      articles {
        _id
      }
      show
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_BLOG_CATEGORY = gql`
  mutation updateBlogCategory($input: UpdateBlogCategoryInput!) {
    updateBlogCategory(input: $input)
  }
`;

export const DELETE_BLOG_CATEGORY = gql`
  mutation deleteBlogCategory($id: ID!) {
    deleteBlogCategory(_id: $id)
  }
`;

export const GET_BLOG_CATEGORYS_BY_ID = gql`
  query getBlogCategoryById($id: ID!) {
    getBlogCategoryById(_id: $id) {
      _id
      name
      image
      description
      articles {
        _id
      }
      show
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

//Blog article

export const GET_BLOG_ARTICLE = gql`
  query getBlogArticles {
    getBlogArticles {
      _id
      smallTitle
      title
      image
      smallDescription
      description
      content {
        title
        type
        data
      }
      show
      categories
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_BLOG_ARTICLE = gql`
  mutation updateBlogArticle($input: UpdateBlogArticleInput!) {
    updateBlogArticle(input: $input)
  }
`;

export const CREATE_BLOG_ARTICLE = gql`
  mutation createBlogArticle($input: CreateBlogArticleInput!) {
    createBlogArticle(input: $input) {
      _id
      smallTitle
      title
      image
      smallDescription
      description
      content {
        title
      }
      show
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const DELETE_BLOG_ARTICLE = gql`
  mutation deleteBlogArticle($id: ID!) {
    deleteBlogArticle(_id: $id)
  }
`;

//Blog section

export const GET_BLOG_SECTIONS = gql`
  query getBlogSections {
    getBlogSections {
      _id
      displayIndex
      name
      type
      categories {
        _id
        name
      }
      articles {
        _id
        title
      }
      attributionType
      teams {
        _id
        name
      }
      teamgroups {
        _id
        name
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const CREATE_BLOG_SECTION = gql`
  mutation createBlogSection($input: CreateBlogSectionInput!) {
    createBlogSection(input: $input) {
      _id
      displayIndex
      name
      type
    }
  }
`;

export const UPDATE_BLOG_SECTION = gql`
  mutation updateBlogSection($input: UpdateBlogSectionInput!) {
    updateBlogSection(input: $input)
  }
`;

export const DELETE_BLOG_SECTION = gql`
  mutation deleteBlogSection($id: ID!) {
    deleteBlogSection(_id: $id)
  }
`;

//Parteners

export const GET_PARTNERS = gql`
  query getSwPartners {
    getSwPartners {
      _id
      name
      logo
      picture
      adresseLine
      iconPicto
      croudFunds {
        _id
        name
        images
      }
      offers {
        _id
        name
      }
      discountCodes {
        _id
        code
        offer {
          _id
          name
        }
        partner {
          _id
          name
        }
      }
      zipCode
      country
      city
      catchementCity
      catchementCountry
    }
  }
`;

export const CREATE_PARTNERS = gql`
  mutation createSwPartner($input: CreatePartnerInput!) {
    createSwPartner(input: $input) {
      _id
      name
      logo
      picture
      adresseLine
      iconPicto
      zipCode
      country
      city
    }
  }
`;

export const DELETE_PARTNERS = gql`
  mutation deleteSwPartner($id: ID!) {
    deleteSwPartner(_id: $id)
  }
`;

export const UPDATE_PARTNERS = gql`
  mutation updateSwPartner($id: ID!, $input: UpdatePartnerInput) {
    updateSwPartner(_id: $id, input: $input) {
      _id
      name
    }
  }
`;

export const ASSIGN_ARTICLE_TEAMS = gql`
  mutation assignFrontPageArticleToGroups(
    $articleId: ID
    $teams: [ID]
    $teamGroups: [ID]
  ) {
    assignFrontPageArticleToGroups(
      articleId: $articleId
      teams: $teams
      teamGroups: $teamGroups
    )
  }
`;

export const UPDATE_ARTICLE_TEAMS = gql`
  mutation updateTeamFrontPageArticle($id: ID!, $articleId: ID!) {
    updateTeamFrontPageArticle(_id: $id, articleId: $articleId)
  }
`;

export const DELETE_ESTABLISHMENT = gql`
  mutation deleteEstablishment($id: ID!) {
    deleteEstablishment(_id: $id)
  }
`;

export const CREATE_DISCOUNTCODE = gql`
  mutation createCode($input: CreateCodeInput!) {
    createCode(input: $input) {
      _id
      type
      code
    }
  }
`;

export const UPDATE_DISCOUNTCODE = gql`
  mutation updateCode($id: ID!, $input: UpdateCodeInput) {
    updateCode(_id: $id, input: $input) {
      _id
      type
      code
      codes
    }
  }
`;

export const DELETE_DISCOUNTCODE = gql`
  mutation deleteCode($id: ID!) {
    deleteCode(_id: $id)
  }
`;

export const CREATE_SPONSORISEDPOST = gql`
  mutation createSponsorisedPost($input: CreateSponsorisedPostInput!) {
    createSponsorisedPost(input: $input) {
      _id
      type
      title
      subTitle
      content {
        _id
        offer
        croudFund
        gain
        type
        title
        text
        image
        video
      }
      canClose
      isFirst
      coverImage
      active
    }
  }
`;

//Quiz
export const GET_QUIZ = gql`
  query getQuizzes($limit: Int!, $offset: Int!) {
    getQuizzes(limit: $limit, offset: $offset) {
      _id
      name
      description
      medialink
      elements {
        mediaLink
        question
        answers {
          answer
          rightAnswer
        }
        description
      }
      spows
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const CREATE_QUIZ = gql`
  mutation createQuiz($input: CreateQuizInput) {
    createQuiz(input: $input) {
      _id
    }
  }
`;

export const UPDATE_QUIZ = gql`
  mutation updateQuiz($id: ID!, $input: UpdateQuizInput) {
    updateQuiz(_id: $id, input: $input)
  }
`;

export const DELETE_QUIZ = gql`
  mutation deleteQuiz($id: ID!) {
    deleteQuiz(_id: $id)
  }
`;

//mission

export const GET_MISSIONS = gql`
  query getMissions($limit: Int!, $offset: Int!) {
    getMissions(limit: $limit, offset: $offset) {
      _id
      name
      shortDescription
      description
      image
      video
      coverVideo
      week
      year
      elements {
        _id
        type
        data
        spows
        times
        watermark
        description
      }
      attributionType
      teams {
        _id
        name
      }
      teamGroups {
        _id
        name
      }
    }
  }
`;

export const CREATE_MISSION = gql`
  mutation createMission($input: CreateMissionInput) {
    createMission(input: $input) {
      _id
    }
  }
`;

export const UPDATE_MISSION = gql`
  mutation updateMission($id: ID!, $input: UpdateMissionInput) {
    updateMission(_id: $id, input: $input)
  }
`;

export const DELETE_MISSION = gql`
  mutation deleteMission($id: ID!) {
    deleteMission(_id: $id)
  }
`;

export const GET_MISSION_BY_YEAR = gql`
  query getMissionsByYear($year: String) {
    getMissionsByYear(year: $year) {
      _id
      name
      shortDescription
      description
      image
      video
      coverVideo
      week
      year
      elements {
        _id
        type
        data
        spows
        times
        watermark
        description
      }
      attributionType
      teams {
        _id
        name
      }
      teamGroups {
        _id
        name
      }
    }
  }
`;
