import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

const AuthGuard = ({ children }) => {
  //const { isLoggedIn } = useAuth();

  if (!localStorage.getItem("accessToken")) {
    return <Redirect to="/auth/signin-1" />;
  }


  

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
