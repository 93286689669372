import React, {useEffect} from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

const GuestGuard = ({ children }) => {
  //const { isLoggedIn } = useAuth();


  if (localStorage.getItem("accessToken")) {
    return <Redirect to="/app/dashboard/analytics" />;
  }

  

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
